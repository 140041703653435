/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, TweenMax) => {

        /*
        |
        | Constants
        |-----------
        */
        const
            $body =	$('body'),
            $loadMore = $('.load-more'),
            $loadedPostsContainer = $('.loaded-posts'),
            $filterContainer = $('.cpt-filter-container'),
            cptSlug = $filterContainer.data('cpt-slug'),
            total = $loadMore.data('total'),
            currentLang     = $body.data('lang'),
            backLink = $('.nickelback')
        ;

        backLink.on('click', function(){
            window.history.back();
        })


        if ($loadMore.length > 0) {
            /*
            |
            | cpt header
            |-----------------
            */
            $loadMore.on('click', function() {
                ajaxMoreCall();
            });

            /*
            |
            | filters
            |-------
            */
            let
                xhr = null
            ;

            function ajaxMoreCall() {
                if (xhr !== null) {
                    xhr.abort()
                }

                let taxs = {};

                $('.filter-items.active:not(.has-childs)').each(function() {
                    let filter = $(this),
                        taxonomy = filter.data('taxonomy'),
                        slug = filter.data('slug');

                    if (taxonomy in taxs) {
                        taxs[taxonomy].push(slug);
                    } else {
                        taxs[taxonomy] = [slug];
                    }
                });

                let offset = $loadedPostsContainer.find('.news-line').length;

                xhr = $.ajax({
                    url: '/ajax/'+ cptSlug +'/more',
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        taxs: taxs,
                        offset: offset,
                        lang: currentLang
                    },
                    beforeSend: () => {
                        $loadedPostsContainer.after('<div class="loader"></div>');
                    },
                    success: (response, status) => {
                        $loadedPostsContainer.append(response);

                        $('.loader').remove();

                        if (!response) {
                            TweenMax.to($loadMore, .5, {autoAlpha: 0});
                        }

                        xhr = null;

                        $('.news-line').on('click', function(e){

                            e.preventDefault()
                            const index = $(this).data('index')
                            const slug = $(this).attr('id')

                            const param = '?id=' + index;
                            window.history.pushState('', '', param);
                            window.location.href = slug

                        })


                    },
                    error: (response, status, error) => {
                        console.log(response, status, error);
                    }
                });
            }
        }
    }
}

