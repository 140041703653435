/*** IMPORTS FROM imports-loader ***/
var define = false;

import Swiper from "swiper/js/swiper.min";

export default {
	init: (app, CookieManager, Swiper) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $body         = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose  = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
		;


        /*
		|
		| Cookie Manager
		|-----------------
		*/
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });


        /* Scroll anime */
        jQuery('.item-nav a[href*=\\#]').click(function (event) {
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 60
            }, 1000);
            event.preventDefault();
        });

        /* Scroll top */
        jQuery(document).ready(function($) {
            $(window).scroll(function(){
                if ($(this).scrollTop() > 200) {
                    $('.scrollToTop').fadeIn();
                } else {
                    $('.scrollToTop').fadeOut();
                }
            });
            $('.scrollToTop').click(function(){
                $('html, body').animate({scrollTop : 0},800);
                return false;
            });
        });

        /*
		|
		| Reduce menu on scroll
		|-----------------
		*/
        function minimenu() {
            if ($(window).scrollTop() > 20) {
                $('#header').addClass('mini');
            } else {
                $('#header').removeClass('mini');
            }
        }

        minimenu();
        $(window).scroll(function () {
            minimenu();
        });




        /* slider home */
        var swiper = new Swiper('#page-home .swiper-container', {
            slidesPerView: 1,
            loop: true,
            effect: 'fade',
            speed: 1000,
            autoplay: true,

            grabCursor: true,
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            }
        });





        $body.on('loaderEnd', () => console.log('ended'))
	}
}
