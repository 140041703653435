/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Menu, Kira, ScrollMagic) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $body             = $('body'),
            $pageLoader       = $('.page-loader'),
            scrollAreas       = document.querySelectorAll('.scroll-area'),
            $menuWrapper      = $('#mobile-menu'),
            $menuButton       = $('#header .btn-menu'),
            $menuBars         = $menuButton.find('.item-burger > span'),
            $menuClose        = $menuWrapper.find('.item-close'),
            $menuStaggerItems = $menuWrapper.find('[data-stagger-item]')
		;



        window.sr = ScrollReveal({ reset: false });
        sr.reveal('.bigtitle', {origin: 'bottom', distance: '0px', duration: 2000});
        sr.reveal('.animright', {origin: 'right', distance: '200px', duration: 2000});
        sr.reveal('.animfade', {origin: 'bottom', distance: '20px',duration: 2000});
        sr.reveal('.top_label li', {origin: 'bottom', interval: 50, distance: '20px',duration: 2000}, 250);
        sr.reveal('.animbottom', {origin: 'bottom', distance: '200px', duration: 1000}, 250);
        sr.reveal('.animbottom2', {origin: 'bottom', distance: '200px', duration: 2000}, 150);



		/*
        |
        | Scroll Areas
        |---------------
        */
		Array.prototype.forEach.call(scrollAreas, element => {
			Scrollbar.init(element);
		});


        /*
		|
		| Loader
		|---------
        */
        if (sessionStorage.getItem('loaded_once') === null) {
            sessionStorage.setItem('loaded_once', 'loaded_once');
        } 
        
        if ($pageLoader.hasClass('active')){
            const loaderTl = new TimelineMax({ paused: true, /*onComplete: () => $pageLoader.remove()*/ });

            loaderTl.to($pageLoader.find('.item-loadbar-inner'), 0.4, { scaleX: 1, ease: Power0.easeNone }, 'start')
            loaderTl.to($pageLoader.find('.item-content'), 0.8, { autoAlpha: 0, ease: Power1.easeOut }, '-=0')
            loaderTl.addCallback(() => { app.dispachEvent($body, 'loaderEnd'); })
            loaderTl.to($pageLoader, 0.8, { autoAlpha: 0, ease: Power1.easeOut }, '-=0')

            $(window).on('load', function () {
                loaderTl.play();
            });
        } else {
            app.dispachEvent($body, 'loaderEnd');
        }


        /*
		|
		| Menu
		|-------
        */
        const menu = new Menu($menuWrapper, $menuButton, {
            reverseTimeScale: 2
        });

        menu.menuTimeline
            .to($menuBars.eq(1), 0.3, { autoAlpha: 0 }, 'start')
			.to($menuBars.eq(0), 0.5, { x: 0, y: 8, rotation: 45, ease: Power1.easeOut }, 'start')
            .to($menuBars.eq(2), 0.5, { x: 0, y: -8, rotation: -45, ease: Power1.easeOut }, 'start')
			.to($menuWrapper, 0.5, { autoAlpha: 1, ease: Power1.easeOut }, 'start+=0.3')
            .staggerFrom($menuStaggerItems, 0.6, { autoAlpha: 0, x: 20, ease: Power1.easeOut }, '0.1', '+=0')
        
        menu.init();

        /*
		|
		| Kira
		|-------
        */
        const kira = new Kira(ScrollMagic, {
            debug: false,
            loadEvent: {
                domElement: $body,
                eventName: 'loaderEnd'
            },
            optimize: true,
            options: {
                start: '-=0.6',
                triggerHook: 'onEnter'
            }
        });

        /*
		| fadeInUp.parallax.sm
		|-----------------------
        */
        kira.add('fadeInUp', ($item, timeline, start) => {
            timeline.from($item, 0.8, { y: 30, autoAlpha: 0, ease: Power1.easeOut }, start)
        });
        
        /*
		| fadeInUp.parallax.sm
		|-----------------------
        */
        kira.add('fadeInUp.parallax.sm', ($item, timeline, start) => {
            timeline.from($item, 0.8, { y: 100, autoAlpha: 0, ease: Sine.easeOut }, start)
        });

        /*
		| fadeInUp.parallax
		|--------------------
        */
        kira.add('fadeInUp.parallax', ($item, timeline, start) => {
            timeline.from($item, 1, { y: 80, autoAlpha: 0, ease: Power1.easeOut }, start)
        });

        /*
		| fadeInUp.parallax.sm
		|-----------------------
        */
        kira.add('fadeInLeft.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFrom($item.find('[data-stagger-item]'), 0.6, { autoAlpha: 0, x: 20, ease: Power1.easeOut }, '0.1', start)
        });

        kira.add('fadeInUp.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFrom($item.find('[data-stagger-item]'), 0.6, { autoAlpha: 0, y: 20, ease: Power1.easeOut }, '0.1', start)
        });

        /*
		| fadeInUp.parallax
		|--------------------
        */
        kira.add('scaleX', ($item, timeline, start) => {
            timeline.from($item, 1, { scaleX: 0, transformOrigin: 'left top', ease: Expo.easeInOut }, start)
        });

        kira.init();
	}
}
